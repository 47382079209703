<template>
    <div class="w-100">
        <button v-if="['new', 'edit'].includes(form_type)" type="button" class="btn btn-success"
            @click.prevent="addItem()">Aggiungi {{ field.repeater_singular }}</button>
        <h3 v-if="['view', 'delete'].includes(form_type)">{{ field.repeater_plural }}</h3>
        <template v-for="(item, index) in items">
            <hr>
            <div class="row mt-4">
                <div class="col-md-10">
                    <h3>{{ field.repeater_singular }} n. {{ index + 1 }}</h3>
                </div>

                <div class="col-md-2">
                    <button v-if="['new', 'edit'].includes(form_type) && item.repeater_removable" type="button"
                        class="btn btn-danger" @click.prevent="removeItem(index)">Rimuovi {{ field.repeater_singular
                        }}</button>
                </div>
            </div>
            <FormFields :form_type="form_type"
                :errors="((typeof internalErrors[field.name] !== 'undefined') && (typeof internalErrors[field.name][index] !== 'undefined') ? internalErrors[field.name][index] : [])"
                v-model="item.fields" :field_prefix_id="field_prefix + '_' + index + '_'" :field_suffix_id="''"
                v-on:remove-error="removeError(index, $event)" />
        </template>
        <hr v-if="items.length">
        <button v-if="['new', 'edit'].includes(form_type) && items.length" type="button" class="btn btn-success"
            @click.prevent="addItem()">Aggiungi
            {{ field.repeater_singular }}</button>
        <template v-if="typeof internalErrors[field.name] !== 'undefined' && !items.length">
            <div v-for="(error, index) in internalErrors[field.name]" class="invalid-feedback" style="display: block;">
                {{ error }}</div>
        </template>
    </div>
</template>

<script>

export default {
    name: 'FieldRepeater',

    props: ['form_type', 'errors', 'value', 'field', 'fields', 'field_prefix'],

    data() {
        return {
            initialized: false,
            items: [],
            internalErrors: [],
        };
    },

    created() {
        this.internalErrors = this.errors;
    },

    mounted() {
    },

    watch: {
        'errors': {
            handler(errors) {
                this.internalErrors = errors;
            },
            deep: true,
        },

        'fields': {
            handler: function (newValue) {
                if (this.fields.length && !this.items.length && !this.initialized) {
                    let fields = JSON.parse(JSON.stringify(this.fields));
                    this.items.push({
                        repeater_unique_id: this.generateRandomId(),
                        repeater_removable: true,
                        fields: fields,
                    });

                    this.initialized = true;
                }
            },
            deep: true,
        },

        'items': {
            handler: function (newValue) {
                for (let i in this.items) {
                    for (let j in this.items[i].fields) {
                        if (this.items[i].fields[j].type === 'date') {
                            this.items[i].fields[j].disable_date = function (date) {
                                let passes = true;
                                let check = true;

                                if (typeof this.maxdate !== "undefined") {
                                    if (this.maxdate === "today") {
                                        check = true;
                                        if (date < new Date(new Date().setHours(0, 0, 0, 0))) {
                                            check = false;
                                        }
                                        passes = passes && check;
                                    }

                                    check = true;
                                    if (date > new Date(new Date(this.maxdate).setHours(0, 0, 0, 0))) {
                                        check = false;
                                    }
                                    passes = passes && check;
                                }

                                if (typeof this.mindate !== "undefined") {
                                    check = true;
                                    if (date < new Date(new Date(this.mindate).setHours(0, 0, 0, 0))) {
                                        check = false;
                                    }
                                    passes = passes && check;
                                }

                                return !passes;
                            }.bind(this.items[i].fields[j]);
                        }
                    }
                }
                this.$emit('input', newValue);
            },
            deep: true,
        },

        'value': {
            handler: function (newValue) {
                this.items = newValue;
            },
            deep: true,
        },
    },

    methods: {
        removeError(index, fieldName) {
            let all_empty = true;

            if (typeof this.internalErrors !== "undefined") {
                if (typeof this.internalErrors[this.field.name] !== "undefined") {

                    let do_scan = true;
                    if (typeof this.internalErrors[this.field.name][0] !== "undefined") {
                        if (typeof this.internalErrors[this.field.name][0] !== "object" && this.internalErrors[this.field.name][0] !== null) {
                            do_scan = false;
                        }
                    }

                    if (do_scan) {
                        for (let i in this.internalErrors[this.field.name]) {
                            if (typeof this.internalErrors[this.field.name][i] !== "undefined") {
                                if (Object.keys(this.internalErrors[this.field.name][i]).length !== 0) {
                                    all_empty = false;
                                }
                            }
                        }
                    }
                }
            }

            if (all_empty) {
                this.$emit('remove-error', this.field.name);
            }
        },

        addItem() {
            if (this.fields.length) {
                let fields = JSON.parse(JSON.stringify(this.fields));
                this.items.push({
                    repeater_unique_id: this.generateRandomId(),
                    repeater_removable: true,
                    fields: JSON.parse(JSON.stringify(this.fields)),
                });
            }
        },

        generateRandomId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);

                return v.toString(16);
            });
        },

        removeItem(index) {
            this.items.splice(index, 1);
        },
    },

    components: {
        FormFields: () => import('@/view/components/FormFields.vue'),
    },
};
</script>
