<template>
    <input
        ref="inputRef"
        :value="formattedValue"
    >
</template>

<script>
 import { watch } from '@vue/composition-api'
 import useCurrencyInput from 'vue-currency-input'

 export default {
     name: 'CurrencyInput',
     props: {
         value: Number,
         options: Object
     },
     setup (props) {
         const {
             formattedValue,
             inputRef
         } = useCurrencyInput(props.options)

         return { inputRef, formattedValue }
     }
 }
</script>
