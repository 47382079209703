<template>
    <div>
        <button class="btn btn-primary btn-sm mt-2" type="button" @click.prevent="showModal"><i class="fas fa-id-card"></i> Inserisci Nuovo Tecnico</button>

        <b-modal ref="modal" size="xl" hide-header hide-footer>
            <SmartForm
                ref="modalform"
                base_url="/sportingclub/board"
                base_path="/sportingclub/board"
                form_type="new"
                :fixed_filters="[{ name: 'idsportingclub', filter: idsportingclub }, { name: 'isdirector', filter: 0 }, { name: 'roletypes', filter: 'organization' }]"
                :is_dialog="true"
                :redirect_after_save="false"
                v-on:close-dialog="closeModal"
                v-on:values-saved="valuesSaved"
            >
            </SmartForm>
        </b-modal>
    </div>
</template>

<script>
 import Swal from 'sweetalert2';

 export default {
     name: 'NewUserTechnicalButton',

     data() {
         return {
             loading: false,
             idsportingclub: null,
         };
     },

     methods: {
         showModal() {
             if (this.idsportingclub) {
                 let vm = this;
                 setTimeout(function() {
                     vm.$refs.modal.show();
                 }, 200);
             }
             else {
                 Swal.fire({
                     title: 'Nessun Sodalizio selezionato',
                     text: "Per effettuare l\'inserimento di un nuovo tecnico selezionare un sodalizio",
                     icon: 'warning',
                     showConfirmButton: true,
                     confirmButtonText: 'Ok',
                 });
             }
         },

         closeModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs.modal.hide();
             }, 200);
         },

         valuesSaved() {
             this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$emit('new-user-technical-saved');
         },
     },

     components: {
         SmartForm: () => import('@/view/components/SmartForm.vue')
     },
 };
</script>
